<template>
  <el-select
    v-model="innerValue"
    v-bind="innerAttrs"
    @change="onChange"
    @blur="onBlur"
    v-on="$listeners"
  >
    <el-option
      v-for="item in merchantList"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import { companyApi } from "@/services/apis/company/index";
// import emitter from "element-ui/src/mixins/emitter";

export default {
  name: "BaseMerchantSelector",

  // mixins: [emitter],

  props: {
    value: {
      type: [String, Number],
      default: "",
    },
  },

  data() {
    return {
      merchantList: [],
    };
  },

  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    innerAttrs() {
      return {
        placeholder: "请选择",
        clearable: true,
        filterable: true,
        ...this.$attrs,
      };
    },
  },

  mounted() {
    this.getUnderMerchantList();
  },

  methods: {
    async getUnderMerchantList() {
      const res = await companyApi.getUnderCompany({
        pageNum: 1,
        pageSize: 100000,
      });
      this.merchantList = res.list;
      this.$emit("complete", res);
    },

    onChange(val) {
      this.$emit("change", val);
      // this.dispatch("ElFormItem", "el.form.change", this.value);
    },

    onBlur(val) {
      this.$emit("blur", val);
      // this.dispatch("ElFormItem", "el.form.blur", this.value);
    },
  },
};
</script>
