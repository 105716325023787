<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card
      v-if="ruleForm.chargeModel"
      class="el-main cg-relative"
      :body-style="{ paddingBottom: '60px' }"
    >
      <el-row>
        <el-col :span="16">
          <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px">
            <div class="cg-flex cg-items-center cg-mb-20">
              <div class="el-descriptions__title cg-mr-20">定价信息</div>
              <el-button @click="onClickSuggest">查看建议定价</el-button>
            </div>
            <el-form-item label="所属商户" prop="companyId">
              <base-merchant-selector
                v-model="ruleForm.companyId"
                class="cg-w-1/2"
                :clearable="false"
                @complete="onFetchMerchantComplate"
              />
            </el-form-item>
            <el-form-item label="定价名称" prop="chargePlanName">
              <el-input
                v-model="ruleForm.chargePlanName"
                class="cg-w-1/2"
                maxlength="20"
                placeholder="请输入定价名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="计费模式" prop="type">
              <el-radio-group :value="ruleForm.chargeModel" @input="onPlanTypeChange">
                <el-radio :label="1">按时计费-时长下单</el-radio>
                <el-radio :label="2">按时计费-金额下单</el-radio>
                <el-radio :label="3">功率计费-时长下单</el-radio>
                <el-radio :label="4">功率计费-金额下单</el-radio>
              </el-radio-group>
            </el-form-item>
            <template v-if="!selectedChargePlan.isPower">
              <el-form-item label="起步费用" required>
                <div class="cg-flex cg-items-center">
                  <div class="cg-w-1/2 cg-flex cg-items-center">
                    <div class="cg-flex-1">
                      <el-form-item class="!cg-mb-0" prop="baseAmount">
                        <base-input
                          v-model="ruleForm.baseAmount"
                          input-type="decimal"
                          placeholder="请输入起步费用"
                        ></base-input>
                      </el-form-item>
                    </div>
                    <div class="cg-px-8">元 /</div>
                    <div class="cg-flex-1">
                      <el-form-item class="!cg-mb-0" prop="baseMinutes">
                        <el-select v-model="ruleForm.baseMinutes">
                          <el-option
                            v-for="item in baseMinutesOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="cg-pl-8">分钟</div>
                </div>
              </el-form-item>
              <el-form-item label="加时费用" required>
                <div class="cg-flex cg-items-center">
                  <div class="cg-w-1/2 cg-flex cg-items-center">
                    <div class="cg-flex-1">
                      <el-form-item class="!cg-mb-0" prop="overtimeAmount">
                        <base-input
                          v-model="ruleForm.overtimeAmount"
                          input-type="decimal"
                          placeholder="请输入加时费用"
                        ></base-input>
                      </el-form-item>
                    </div>
                    <div class="cg-px-8">元 /</div>
                    <div class="cg-flex-1">
                      <el-form-item class="!cg-mb-0" prop="overtimeMinutes">
                        <el-select v-model="ruleForm.overtimeMinutes">
                          <el-option
                            v-for="item in overtimeMinutesOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="cg-pl-8">分钟</div>
                </div>
              </el-form-item>
            </template>
            <power-input-table
              v-else
              v-model="ruleForm.powerChargeList"
              :power-error-list.sync="powerErrorList"
              :base-minutes-options="baseMinutesOptions"
              :overtime-base-minutes-options="overtimeMinutesOptions"
              @check="checkPowerList"
            ></power-input-table>
            <el-form-item label="免费时长" prop="freeChargeDuration">
              <div class="cg-flex cg-items-center">
                <base-input
                  v-model="ruleForm.freeChargeDuration"
                  class="cg-w-1/2"
                  input-type="number"
                  :maxlength="3"
                  placeholder="请输入免费时长"
                ></base-input>
                <div class="cg-px-8">分钟</div>
                <el-tooltip placement="top" content="订单结束时不满该时间，则不计算该订单费用">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="单日免费次数" prop="freeChargeCount">
              <div class="cg-flex cg-items-center">
                <base-input
                  v-model="ruleForm.freeChargeCount"
                  class="cg-w-1/2"
                  input-type="number"
                  :maxlength="2"
                  placeholder="请输入单日免费次数"
                ></base-input>
                <div class="cg-px-8">次</div>
                <el-tooltip placement="top" content="每个用户每天可参与免费时长的次数">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="最大时长" prop="maxChargeDuration">
              <div class="cg-flex cg-items-center">
                <el-select v-model="ruleForm.maxChargeDuration" class="cg-w-1/2">
                  <el-option
                    v-for="item in maxChargeDurationOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
                <div class="cg-px-8">分钟</div>
                <el-tooltip placement="top" content="可以选择的最大充电时间">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="定价描述" prop="chargePlanDescription">
              <el-input
                v-model="ruleForm.chargePlanDescription"
                class="cg-w-1/2"
                type="textarea"
                placeholder="请输入定价描述"
                :maxlength="100"
              ></el-input>
            </el-form-item>
            <el-descriptions title="下单设置"></el-descriptions>
            <el-form-item label="是否充满自停" prop="fullStop">
              <div class="cg-flex cg-items-center cg-h-32">
                <el-radio-group
                  v-model="ruleForm.fullStop"
                  class="cg-mr-20"
                  @input="onFullStopChange"
                >
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="2">不开启</el-radio>
                </el-radio-group>
                <el-tooltip placement="top" content="作用于是否展示用户选择充满自停的选项">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="候选顺序" prop="candidatesOrder">
              <div class="cg-flex cg-items-center cg-h-32">
                <el-radio-group v-model="ruleForm.candidatesOrder" class="cg-mr-20">
                  <el-radio :label="1">从大到小</el-radio>
                  <el-radio :label="2">从小到大</el-radio>
                </el-radio-group>
                <el-tooltip placement="top" content="作用于用户选择充电方式的展示顺序">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
            </el-form-item>
            <el-form-item label="候选方式" required>
              <div class="cg-flex cg-items-center cg-mr-20">
                <div v-for="(_, i) in ruleForm.candidatesType" :key="i" class="cg-mr-8 cg-w-160">
                  <el-form-item :error="candidatesTypeError[i]" label-width="0" class="!cg-mb-0">
                    <div class="cg-flex cg-items-center">
                      <base-input
                        v-model="ruleForm.candidatesType[i]"
                        class="cg-w-100"
                        :input-type="selectedChargePlan.isTime ? 'number' : 'decimal'"
                        placeholder="请输入"
                        @input="(val) => onCandidatesTypeInput(val, i)"
                      />
                      <div class="cg-pl-8">
                        {{ selectedChargePlan.isTime ? "分钟" : "元" }}
                      </div>
                    </div>
                  </el-form-item>
                </div>
                <el-tooltip placement="top" content="作用于用户快捷选择充电方式的内容">
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </div>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8">
          <price-preview
            :pricing-option-info="pricingOptionInfo"
            show-update-button
            @click="onClickUpdatePreview"
          />
        </el-col>
      </el-row>
      <div class="cg-absolute cg-bottom-0 cg-bg-white cg-py-12 cg-px-16">
        <el-button type="primary" :loading="addingOrUpdating" @click="onClickSubmit">
          立即提交
        </el-button>
        <el-button @click="onClickCancel">取消</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import { chargePlanTransform, COMPANY_CHARGE_PLAN_CONST } from "@charge/common";
import { cloneDeep } from "lodash-es";
import { mapActions, mapState } from "vuex";

import BaseMerchantSelector from "@/components-new/BaseMerchantSelector/index.vue";
import { companyChargePlanApi } from "@/services/apis/company/charge-plan";
import { eventBus, eventBusKeys } from "@/utils/event-bus";

import PowerInputTable from "./PowerInputTable.vue";
import PricePreview from "./PricePreview.vue";

export default {
  name: "PriceAction",

  components: {
    BaseMerchantSelector,
    PowerInputTable,
    PricePreview,
  },

  props: {
    id: {
      type: Number,
      default: 0,
    },
  },

  data() {
    this.iframe = null;
    return {
      baseMinutesOptions: COMPANY_CHARGE_PLAN_CONST.baseMinutesOptions,
      overtimeMinutesOptions: COMPANY_CHARGE_PLAN_CONST.overtimeMinutesOptions,
      maxChargeDurationOptions: COMPANY_CHARGE_PLAN_CONST.maxChargeDurationOptions,
      suggestplan: {},
      templatePlan: {},
      ruleForm: {},
      candidatesTypeError: ["", "", "", ""],
      powerErrorList: [],
      rules: {
        companyId: [{ required: true }],
        chargePlanName: [{ required: true, message: "请输入定价名称" }],
        chargeModel: [{ required: true }],
        baseMinutes: [{ required: true }],
        baseAmount: [{ required: true, message: "请输入起步费用" }],
        overtimeMinutes: [{ required: true }],
        overtimeAmount: [{ required: true, message: "请输入加时费用" }],
        freeChargeDuration: [
          { required: true, message: "请输入免费时长" },
          {
            validator: (_, val, callback) => {
              let baseMinutes = this.ruleForm.baseMinutes;
              if (this.selectedChargePlan.isPower) {
                baseMinutes = Math.min(...this.ruleForm.powerChargeList.map((v) => v.baseMinutes));
              }
              if (baseMinutes <= val) {
                callback(new Error("免费时长必须小于起步时长"));
              } else if (val < 6 || val > 999) {
                callback(new Error("请输入6-999的数字"));
              } else {
                callback();
              }
            },
          },
        ],
        freeChargeCount: [{ required: true, message: "请输入单日免费次数" }],
        maxChargeDuration: [
          { required: true },
          {
            validator: (_, val, callback) => {
              let baseMinutes = this.ruleForm.baseMinutes;
              if (this.selectedChargePlan.isPower) {
                baseMinutes = Math.max(...this.ruleForm.powerChargeList.map((v) => v.baseMinutes));
              }
              if (baseMinutes > val) {
                callback(new Error("选择的最大时长必须大于等于起步时长"));
              } else {
                callback();
              }
            },
          },
        ],
        fullStop: [{ required: true }],
        candidatesOrder: [{ required: true }],
      },
      pricingOptionInfo: {},
    };
  },

  computed: {
    ...mapState("loading", ["effects"]),

    addingOrUpdating() {
      return [companyChargePlanApi.addEffectKey, companyChargePlanApi.editEffectKey].some(
        (key) => !!this.effects[key]
      );
    },
    selectedChargePlan() {
      return this.suggestplan[this.ruleForm.chargeModel] || {};
    },
    selectableRange() {
      const result = {};
      if (this.selectedChargePlan.isTime) {
        result.min = this.ruleForm.baseMinutes;
        result.max = this.ruleForm.maxChargeDuration;
      } else {
        result.min = this.ruleForm.baseAmount;
        result.max = this.ruleForm.overtimeAmount
          ? this.calcAmountByTime(this.ruleForm.maxChargeDuration, this.ruleForm)
          : "";
      }
      if (this.selectedChargePlan.isPower) {
        if (this.selectedChargePlan.isTime) {
          result.min = this.ruleForm.powerChargeList[0].baseMinutes;
          result.max = this.ruleForm.maxChargeDuration;
        } else {
          result.min = this.ruleForm.powerChargeList[0].baseAmount;
          result.max = this.ruleForm.powerChargeList[0].overtimeAmount
            ? this.calcAmountByTime(
                this.ruleForm.maxChargeDuration,
                this.ruleForm.powerChargeList[0]
              )
            : "";
        }
      }
      return result;
    },
  },

  watch: {
    id(val) {
      if (val) {
        this.getPlanDetail();
      }
    },
  },

  async mounted() {
    await this.getSuggestPlanList();
    if (this.id) {
      await this.getPlanDetail();
    }
    if (this.id) {
      eventBus.on(eventBusKeys.editUseSuggestPrice, this.handleUseSuggestPrice);
    } else {
      eventBus.on(eventBusKeys.addUseSuggestPrice, this.handleUseSuggestPrice);
    }
  },

  beforeDestroy() {
    if (this.id) {
      eventBus.off(eventBusKeys.editUseSuggestPrice, this.handleUseSuggestPrice);
    } else {
      eventBus.off(eventBusKeys.addUseSuggestPrice, this.handleUseSuggestPrice);
    }
  },

  methods: {
    ...mapActions(["delView"]),

    handleUseSuggestPrice(chargeModel) {
      this.templatePlan[chargeModel] = cloneDeep(this.suggestplan[chargeModel]);
      this.initData(chargeModel);
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },

    calcAmountByTime(maxTime, ruleObj) {
      return (
        ruleObj.baseAmount +
        Math.ceil((maxTime - ruleObj.baseMinutes) / ruleObj.overtimeMinutes) *
          ruleObj.overtimeAmount
      );
    },

    async getSuggestPlanList() {
      const suggestPlanOrigin = await companyChargePlanApi.defaultChargePlan();
      this.suggestplan = chargePlanTransform.convertDefaultChargeValue(suggestPlanOrigin);
      this.templatePlan = cloneDeep(
        chargePlanTransform.convertDefaultChargeToForm(suggestPlanOrigin)
      );
      if (!this.id) {
        this.initData(COMPANY_CHARGE_PLAN_CONST.chargeModel.baseTime);
      }
    },

    async getPlanDetail() {
      const res = await companyChargePlanApi.detail({ id: this.id });
      this.templatePlan = Object.values(this.templatePlan).reduce((prev, curr) => {
        let result = {
          ...curr,
          chargePlanName: res.chargePlanName,
        };
        if (curr.chargeModel === res.chargeModel) {
          result = { ...res };
        }
        prev[curr.chargeModel] = result;
        return prev;
      }, {});
      this.initData(res.chargeModel);
    },

    onClickSuggest() {
      const query = {};
      if (this.id) {
        query.id = this.id;
      }
      this.$router.push({ path: "price-suggest", query: query });
    },

    initData(chargeModel, postPreviewdata = true) {
      const ruleForm = cloneDeep(this.templatePlan[chargeModel]);
      if (this.ruleForm.chargeModel) {
        ruleForm.chargePlanName = this.ruleForm.chargePlanName;
        ruleForm.companyId = this.ruleForm.companyId;
      }
      this.ruleForm = cloneDeep(ruleForm);
      postPreviewdata && this.postPreviewData();
      this.initPowerErrorList();
      this.candidatesTypeError = ["", "", "", ""];
      if (this.selectedChargePlan.isFull) {
        this.candidatesTypeError.push("");
      }
    },

    async postPreviewData() {
      if (this.ruleForm.chargeModel) {
        const data = chargePlanTransform.formToData(this.ruleForm, this.id);
        data.chargePlanName = undefined;
        data.companyId = undefined;
        this.pricingOptionInfo = await companyChargePlanApi.preview(data);
      }
    },

    initPowerErrorList() {
      if (this.selectedChargePlan.isPower) {
        this.powerErrorList = this.ruleForm.powerChargeList.map(() => ({
          maxPower: "",
          baseAmount: "",
          overtimeAmount: "",
        }));
      }
    },

    onFetchMerchantComplate({ list }) {
      const defaultMerchantId = list[0].id;
      Object.values(COMPANY_CHARGE_PLAN_CONST.chargeModel).forEach((key) => {
        this.suggestplan[key].companyId = defaultMerchantId;
        this.templatePlan[key].companyId = defaultMerchantId;
      });
      this.ruleForm = { ...this.ruleForm, companyId: defaultMerchantId };
    },

    onPlanTypeChange(val) {
      this.templatePlan[this.ruleForm.chargeModel] = this.ruleForm;
      this.$nextTick(() => {
        this.initData(val, false);
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate();
        });
      });
    },

    onFullStopChange(val) {
      if (val === 1) {
        this.ruleForm.candidatesType.pop("");
        this.candidatesTypeError.pop();
      } else {
        this.ruleForm.candidatesType.push("");
        this.candidatesTypeError.push("");
      }
    },

    checkCandidatesType() {
      this.candidatesTypeError = this.ruleForm.candidatesType.map((v, i) => {
        let result = "";
        if (!v) {
          i === 0 && this.ruleForm.candidatesType.every((v) => !v) && (result = "候选方式不能为空");
        } else {
          if (v < this.selectableRange.min || v > this.selectableRange.max) {
            result = `只能填写 ${this.selectableRange.min}-${this.selectableRange.max} 的数值`;
          } else if (this.ruleForm.candidatesType.slice(i + 1).includes(v)) {
            result = `候选方式不能重复`;
          }
        }
        return result;
      });
      return this.candidatesTypeError.every((v) => !v);
    },

    setCandidatesTypeError(index, message) {
      this.candidatesTypeError = this.candidatesTypeError.map((v, i) =>
        i === index ? message : v
      );
    },

    getCandidatesTypeByIndex(index) {
      return this.ruleForm.candidatesType
        .slice(0, index)
        .concat(this.ruleForm.candidatesType.slice(index + 1));
    },

    onCandidatesTypeInput(val, index) {
      if (val === "") {
        this.setCandidatesTypeError(
          index,
          Object.values(this.ruleForm.candidatesType).some((val) => !!val) ? "" : "候选方式不能为空"
        );
        this.candidatesTypeError = this.candidatesTypeError.map((v, i) => {
          if (i !== index && v === "候选方式不能为空") {
            return "";
          }
          return v;
        });
        return;
      }
      const otherCandidatesType = this.getCandidatesTypeByIndex(index);
      if (!this.selectableRange.min) {
        this.setCandidatesTypeError(index, `请先填写起步费用`);
      } else if (!this.selectableRange.max) {
        this.setCandidatesTypeError(index, `请先填写加时费用`);
      } else if (this.selectableRange.max < val || this.selectableRange.min > val) {
        this.setCandidatesTypeError(
          index,
          `只能填写 ${this.selectableRange.min}-${this.selectableRange.max} 的数值`
        );
      } else if (otherCandidatesType.includes(val)) {
        this.setCandidatesTypeError(index, `候选方式不能重复`);
      } else {
        this.setCandidatesTypeError(index, "");
      }
    },

    checkPowerList(key, index) {
      const errorMap = {
        maxPower: (val, i) => {
          let result = "";
          const item = this.ruleForm.powerChargeList[i];
          if (!val) {
            result = "请输入功率范围";
          } else if (item.maxPower < item.minPower) {
            result = "请设置正确的功率范围";
          }
          return result;
        },
        baseAmount: (val) => (!val ? "请输入起步费用" : ""),
        overtimeAmount: (val) => (!val ? "请输入加时费用" : ""),
      };
      if (key) {
        this.powerErrorList = this.powerErrorList.map((v, i) => {
          if (i !== index) {
            return v;
          }
          return {
            ...v,
            [key]: errorMap[key](this.ruleForm.powerChargeList[index][key], index),
          };
        });
        return;
      }
      this.powerErrorList = this.ruleForm.powerChargeList.map((v, i) => ({
        maxPower: errorMap.maxPower(v.maxPower, i),
        baseAmount: errorMap.baseAmount(v.baseAmount),
        overtimeAmount: errorMap.overtimeAmount(v.overtimeAmount),
      }));
      return this.powerErrorList.every((v) => !v.maxPower && !v.baseAmount && !v.overtimeAmount);
    },

    async checkForm() {
      return new Promise((resolve, reject) => {
        const checkCandidatesTypeResult = this.checkCandidatesType();
        let checkPowerListResult = true;
        if (this.selectedChargePlan.isPower) {
          checkPowerListResult = this.checkPowerList();
        }
        this.$refs.ruleForm.validate((valid) => {
          if (valid && checkCandidatesTypeResult && checkPowerListResult) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },

    async onClickSubmit() {
      await this.checkForm();
      if (!this.id) {
        this.handleAddChargePlan();
      } else {
        this.handleEditChargePlan();
      }
    },

    async handleAddChargePlan() {
      await companyChargePlanApi.add(chargePlanTransform.formToData(this.ruleForm, this.id));
      this.handleActionSuccess("添加成功");
      this.onClickCancel();
    },

    async handleEditChargePlan() {
      await companyChargePlanApi.edit(chargePlanTransform.formToData(this.ruleForm, this.id));
      this.handleActionSuccess("编辑成功");
    },

    handleActionSuccess(text) {
      this.$message.success({ message: text });
      eventBus.emit(eventBusKeys.operatePriceSuccess);
    },

    onClickCancel() {
      this.$router.push("/price/price-list");
      this.delView(this.$route);
    },

    async onClickUpdatePreview() {
      await this.checkForm();
      this.postPreviewData();
    },
  },
};
</script>

<style lang="scss" scoped>
._iframe-wrap {
  width: 370px;
  height: 640px;
  padding: 48px 13px 25px;
  background: url("~@/assets/images/iPhone13.png");
  background-size: cover;
  overflow: hidden;
}
</style>
