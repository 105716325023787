<template>
  <div class="cg-mb-20">
    <el-form-item label="充电费用" required>
      <el-button v-if="value.length < 8" @click="onClickAdd">添加</el-button>
    </el-form-item>
    <el-table :data="value" border>
      <el-table-column label="功率范围（瓦）" align="center">
        <template slot-scope="{ row, $index }">
          <div class="cg-flex cg-flex-col">
            <div class="cg-flex cg-items-center">
              <el-input class="cg-w-100" :value="row.minPower" disabled></el-input>
              <div class="cg-px-8">——</div>

              <el-form-item
                :error="powerErrorList[$index].maxPower"
                :show-message="false"
                label-width="0"
                class="!cg-mb-0"
              >
                <base-input
                  class="cg-w-100"
                  input-type="number"
                  :maxlength="4"
                  :value="row.maxPower"
                  placeholder="请输入"
                  @input="(val) => onInputPower(val, $index)"
                  @blur="(val) => updateError('maxPower', $index)"
                ></base-input>
              </el-form-item>
            </div>
            <span
              v-if="powerErrorList[$index].maxPower"
              class="cg-w-full cg-text-right cg-text-hex-F56C6C"
            >
              {{ powerErrorList[$index].maxPower }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="起步费用" align="center">
        <template slot-scope="{ row, $index }">
          <div class="cg-flex cg-flex-col">
            <div class="cg-flex cg-items-center">
              <el-form-item
                :error="powerErrorList[$index].baseAmount"
                :show-message="false"
                label-width="0"
                class="!cg-mb-0"
              >
                <base-input
                  v-model="row.baseAmount"
                  class="cg-w-60"
                  input-type="decimal"
                  @input="updateError('baseAmount', $index)"
                  @blur="(val) => updateError('baseAmount', $index)"
                ></base-input>
              </el-form-item>
              <div class="cg-px-8">元 /</div>
              <el-select v-model="row.baseMinutes" class="cg-w-80">
                <el-option
                  v-for="item in baseMinutesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div class="cg-pl-8">分钟</div>
            </div>
            <span
              v-if="powerErrorList[$index].baseAmount"
              class="cg-w-full cg-text-left cg-text-hex-F56C6C"
            >
              {{ powerErrorList[$index].baseAmount }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="加时费用" align="center">
        <template slot-scope="{ row, $index }">
          <div class="cg-flex cg-flex-col">
            <div class="cg-flex cg-items-center">
              <el-form-item
                :error="powerErrorList[$index].overtimeAmount"
                :show-message="false"
                label-width="0"
                class="!cg-mb-0"
              >
                <base-input
                  v-model="row.overtimeAmount"
                  class="cg-w-60"
                  input-type="decimal"
                  @input="updateError('overtimeAmount', $index)"
                  @blur="(val) => updateError('overtimeAmount', $index)"
                ></base-input>
              </el-form-item>
              <div class="cg-px-8">元 /</div>
              <el-select v-model="row.overtimeMinutes" class="cg-w-80">
                <el-option
                  v-for="item in overtimeBaseMinutesOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <div class="cg-pl-8">分钟</div>
            </div>
            <span
              v-if="powerErrorList[$index].overtimeAmount"
              class="cg-w-full cg-text-left cg-text-hex-F56C6C"
            >
              {{ powerErrorList[$index].overtimeAmount }}
            </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="{ $index }">
          <el-link v-if="value.length > 1" type="danger" @click="onClickDelete($index)">
            删除
          </el-link>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "PowerInputTable",

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    baseMinutesOptions: {
      type: Array,
      default: () => [],
    },
    overtimeBaseMinutesOptions: {
      type: Array,
      default: () => [],
    },
    powerErrorList: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    updateValue(val) {
      this.$emit(
        "input",
        val.map((v, i) => {
          const result = { ...v };
          result.minPower = i === 0 ? 0 : val[i - 1].maxPower ? val[i - 1].maxPower : 0;
          return result;
        })
      );
    },

    updateError(key, index) {
      this.$emit("check", key, index);
    },

    onInputPower(val, index) {
      const newValue = this.value.map((v, i) => {
        const result = { ...v };
        if (i === index) {
          result.maxPower = val ? parseInt(val) : "";
        }
        return result;
      });
      this.updateValue(newValue);
      this.updateError("maxPower", index);
    },

    onClickDelete(index) {
      this.$emit(
        "update:powerErrorList",
        this.powerErrorList.filter((_, i) => i !== index)
      );
      this.updateValue(this.value.filter((_, i) => i !== index));
    },

    onClickAdd() {
      this.$emit("update:powerErrorList", this.powerErrorList.concat({}));
      this.updateValue(this.value.concat({ maxPower: "", baseMinutes: 240, overtimeMinutes: 60 }));
    },
  },
};
</script>
